import { useWeb3React } from "@web3-react/core"
import { useCallback, useEffect, useMemo, useState } from "react"
import { JsonRpcProvider } from '@ethersproject/providers'
import { IERC1155__factory, MachineAssembler__factory } from "../types/ethers-contracts"

export const machineAddress = process.env.REACT_APP_MACHINE_ADDRESS
if (!machineAddress) throw new Error("machine address undefined")

export const useMachina = () => {
  const { library } = useWeb3React<JsonRpcProvider>()

  const factory = useMemo(() => {
    if (!library) return undefined
    return MachineAssembler__factory.connect(machineAddress, library.getSigner())
  }, [library])

  return factory
}

const fortAddress = process.env.REACT_APP_FORT_ADDRESS
if (!fortAddress) throw new Error("FORT address undefined")

export const useFORT = () => {
  const { library } = useWeb3React<JsonRpcProvider>()

  const fort = useMemo(() => {
    if (!library) return undefined
    return IERC1155__factory.connect(fortAddress, library.getSigner())
  }, [library])

  return fort
}

export const useApproval = () => {
  const fort = useFORT()
  const { account, library } = useWeb3React<JsonRpcProvider>()

  const [isApproved, setIsApproved] = useState<boolean>()

  const update = useCallback(() => {
    if (!fort || !account) return
    const f = async () => {
      const a = await fort.isApprovedForAll(account, machineAddress)
      setIsApproved(a)
    }
    return f()
  }, [fort, account])

  useEffect(() => {
    update()
  }, [update])

  const approveMachineContract = useCallback(() => {
    if (!fort || !library) return
    const signer = library.getSigner()
    const fortSigner = fort.connect(signer)
    return fortSigner.setApprovalForAll(machineAddress, true)
  }, [fort, library])

  return { isApproved, approveMachineContract, updateApprovalStatus: update }
}

export const useAssembly = () => {
  const assembler = useMachina()

  const assemble = useCallback(() => {
    return assembler?.assemble()
  }, [assembler])

  return assemble
}
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import { useCallback } from 'react'

export const chainId = process.env.REACT_APP_CHAIN === 'local' ? 1337 : 19

export const injected = new InjectedConnector({ supportedChainIds: [chainId] })
interface ProviderRpcError extends Error {
  message: string
  code: number
  data?: unknown
}

const songbirdNetworkParams = {
  chainId: '0x13',
  chainName: 'Songbird',
  rpcUrls: ['https://songbird.towolabs.com/rpc'],
  nativeCurrency: {
    name: 'SGB',
    symbol: 'SGB',
    decimals: 18,
  },
  blockExplorerUrls: ['https://songbird-explorer.flare.network'],
}

const useConnect = () => {
  const { activate, error } = useWeb3React()
  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError

  const switchOrAddNetwork = useCallback(async (chainId: 14 | 19 | 1337) => {
    const w = window as typeof window & { ethereum: any }
    try {
      await w.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
    } catch (error) {
      if (
        (error as ProviderRpcError).code === 4902 ||
        (error as ProviderRpcError).code === -32603
      ) {
        try {
          await w.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [songbirdNetworkParams],
          })
        } catch (error) { }
      }
    }
  }, [])

  const connect = useCallback(() => activate(injected), [activate])

  const switchToSongbird = useCallback(async () => {
    await switchOrAddNetwork(19)
  }, [switchOrAddNetwork])

  const switchNetwork = useCallback(async () => {
    await switchOrAddNetwork(chainId)
  }, [switchOrAddNetwork])

  return {
    connect,
    isUnsupportedChainIdError,
    switchToSongbird,
    switchNetwork
  }
}

export default useConnect

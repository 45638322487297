import logo from './assets/flareOracleLogo.png';
import bannerHero from './assets/bannerHero.gif'
import twitterLogo from './assets/twitterLogo.svg'
import discrodLogo from './assets/discordLogo.svg'
import banner from './assets/banner.jpg'
import './App.css';
import { useApproval, useAssembly } from './hooks/useContracts';
import { useCallback, useMemo, useState } from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import useConnect from './hooks/useConnect';
import { SpinnerCircular } from 'spinners-react';

const formatAccount = (a: string) => a.substring(0, 4) + '...' + a.substring(a.length - 4, a.length)

function App() {
  const { isApproved, approveMachineContract, updateApprovalStatus } = useApproval()
  const { account, error } = useWeb3React()
  const assemble = useAssembly()
  const needsApproval = isApproved === false
  const { connect, switchToSongbird } = useConnect()
  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError
  const [loading, setLoading] = useState(false)

  const connectMaybeSwitchChain = () => {
    if (isUnsupportedChainIdError) {
      switchToSongbird()
        .then(() => connect())
    } else {
      connect()
    }
  };

  const handleApprove = useCallback(() => {
    if (isApproved) return

    const f = async () => {
      try {
        const tx = await approveMachineContract()
        setLoading(true)
        if (!tx) return
        await tx.wait()
        updateApprovalStatus()
      }
      catch (e) { }
      finally {
        setLoading(false)
      }
    }

    f()
  }, [isApproved, updateApprovalStatus, approveMachineContract])

  const handleAssembly = useCallback(() => {
    const f = async () => {
      try {
        const tx = await assemble()
        if (!tx) return
        setLoading(true)
        await tx.wait()
        setLoading(false)
        setShowSuccessMessage(true)
        setTimeout(() => {
          setShowSuccessMessage(false)
        }, 7000)
      }
      catch (e) { }
      finally {
        setLoading(false)
      }
    }
    f()
  }, [assemble])

  const buttonOptions = useMemo((): [string | React.ReactNode, () => any] => {
    if (loading) return [<div style={{ width: 100 }}><SpinnerCircular color='black' secondaryColor='white' size={30} speed={130} thickness={100} /></div>, () => { }]
    if (!account || isApproved === undefined) return ['Claim the machine', () => { }]
    else if (isApproved === false) return ["Approve machine assembler", handleApprove]
    else if (isApproved) return ["Claim the machine", handleAssembly]
    else return ["Claim the machine", () => { }]
  }, [account, isApproved, handleApprove, handleAssembly, loading])

  const [buttonText, handleClick] = buttonOptions

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  return (
    <div>
      <div className='menu'>
        <div className='leftSide'>
          <img src={logo} alt='logo' />
        </div>
        <div className='rightSide'>
          <button className="connectButton center" onClick={() => connectMaybeSwitchChain()}>
            {account ? formatAccount(account) : 'Connect'}
          </button>
          <a href='https://twitter.com/flareoracle_io' target='_blank' rel='noreferrer'>
            <img src={twitterLogo} alt='twitter' />
          </a>
          <a href='https://t.co/rPNujJGzlD' target='_blank' rel='noreferrer'>
            <img src={discrodLogo} alt='discrod' />
          </a>
        </div>
      </div>
      <div className='mainSection'>
        <img className='machineLogo' src={bannerHero} alt='machine' />
        <div className='heroSectionContent'>
          <h1>Fortuna Machina</h1>
          <p>The wait is over. Fortuna Machine NFT is finally available. Deposit the 8 gears you collected from our collection and get ready to multiply your $FORT rewards. After approving the machine assembly, clicking the "claim machine" button will only respond to 8 Gear holders. </p>
          <div className='mingTheChain'>
            <button onClick={handleClick}>
              <div className='ming'>
                <p>{buttonOptions[0]}</p>
                <span>8GEARS</span>
              </div>
            </button>
          </div>
          {showSuccessMessage && <div>Claimed successfully.</div>}
        </div>
      </div>
      <section className='whitePaper' style={{ background: `${banner}` }}>
        <div className='whitePaperContent'>
          <h2>$FORT WHITEPAPER</h2>
          <p>The $FORT token system and the $FORT multiplier machine will provide more passive income possibilities. Where early supporters of Flare Oracle profit the most.</p>
          <a href={process.env.PUBLIC_URL + '/Fortuna rewards WhitePaper from Flare Oracle.pdf'}>Read whitepaper</a>
        </div>
      </section>
    </div>
  );
}

export default App;
